import React, { useState, useEffect } from 'react';
import Person from './person.inline.svg';
import styles from './ProjectSection.module.scss';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

export default function MessageAnimation() {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.65
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={classNames(styles.message, {
        [styles.visible]: isVisible
      })}
    >
      <div className={styles.subject}>RE: Project Timeline</div>
      <p>Hi Chris, hope you had a good vacation! I can’t wait to hear all about it.</p>
      <p>
        We’ve been busy here,{' '}
        <span className={styles.highlight}>
          but I’m starting to get
          <br />
          concerned about the project
        </span>{' '}
        timeline.
      </p>
      <Person className={styles.person} />
    </div>
  );
}
